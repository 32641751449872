import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  computed,
  ElementRef,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/ebook/src/environments/environment';
import { firstValueFrom } from 'rxjs';
import {
  LtiActions,
  ltiParams,
  LtiParamsState,
  MessageToLureAction,
  ModalSubmissionType,
  Role,
  ToastsService,
  UPAssignmentService,
  UPBsModalConfig,
  UPBsModalEvent,
  UPUtilityService,
} from 'up';
import { getLtiParams } from '../../store/selectors/ltiparams.selector';

@Component({
  selector: 'ebook-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private readonly routesAllowedToShowAssignButton = ['/instructor/home'];
  private readonly routesAllowedToShowUnpairButton = ['/instructor/home'];
  private readonly routesAllowedToShowExitButton = [
    '/ebook',
    '/error',
    '/instructor/home',
    '/instructor/warning',
  ];
  ltiParams = signal<ltiParams | undefined>(undefined);
  isInstructor = computed<boolean>(
    () => this.ltiParams()?.role?.toLowerCase() === Role.INSTRUCTOR,
  );
  isSelect = computed<boolean>(
    () => this.ltiParams()?.ltiaAction?.toLowerCase() === LtiActions.SELECT,
  );
  isAssigned = computed<boolean>(() => !!this.ltiParams()?.assigned);
  logo: string = '../../../../assets/ebook_Logo.svg';
  isShowAssignButton = computed<boolean>(
    () =>
      !this.isAssigned() &&
      this.isInstructor() &&
      this.isSelect() &&
      this.routesAllowedToShowAssignButton.some((path) =>
        this.currentRoute().toString().includes(path),
      ),
  );
  isShowUnpairButton = computed<boolean>(
    () =>
      this.isInstructor() &&
      this.isSelect() &&
      this.routesAllowedToShowUnpairButton.some((path) =>
        this.currentRoute().toString().includes(path),
      ),
  );
  isShowExitButton = computed<boolean>(() =>
    this.routesAllowedToShowExitButton.some((path) =>
      this.currentRoute().toString().includes(path),
    ),
  );
  ebookName = computed<string>(() => this.ltiParams()?.productTitle ?? '');
  @ViewChild('unpairModal') unpairModal!: ElementRef;

  unpairModalConfig: UPBsModalConfig = {
    title: 'INSTRUCTOR.UNPAIR_MODAL.TITLE',
    submitType: ModalSubmissionType.COMPLETE_SUBMIT_BUTTON,
    submitBtnText: 'GENERIC.MODAL.CONFIRM_BUTTON',
    closeBtnText: 'GENERIC.MODAL.CANCEL_BUTTON',
    featureType: 'welcome-modal',
    hideCloseBtn: false,
    hideFooter: false,
  };
  private currentRoute = signal<string>('/');

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private store: Store<LtiParamsState>,
    private toastsService: ToastsService,
    private translate: TranslateService,
    private upAssignmentService: UPAssignmentService,
    private upUtilityService: UPUtilityService,
  ) {}

  ngOnInit() {
    this.routerPathCheck();
    this.store
      .select(getLtiParams)
      .subscribe((ltiParams) => this.ltiParams.set(ltiParams));
  }

  onExit() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/ebook`);
  }

  async onClickAssignButton() {
    try {
      await firstValueFrom(
        this.upUtilityService.createAssignment(
          `${environment.apiUrl}/ebook/assignment/create`,
        ),
      );
      this.router.navigateByUrl('/instructor/assignstatus', {
        state: {
          responseType: 'success',
        },
      });
    } catch (error) {
      this.router.navigateByUrl('/instructor/assignstatus', {
        state: {
          errorDetails: (error as HttpErrorResponse).error,
          responseType: 'error',
        },
      });
    }
  }

  routerPathCheck() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute.set(event.urlAfterRedirects);
      }
    });
  }

  async unpairProduct() {
    try {
      await firstValueFrom(
        this.upAssignmentService.unpairProduct(
          `${environment.apiUrl}/ebook/product/unpair`,
        ),
      );
      setTimeout(() => {
        this.upUtilityService.setMessageToLure(
          MessageToLureAction.UNPAIR_SECTION,
        );
        this.upUtilityService.closeApp(`${environment.apiUrl}/ebook`);
      }, 2000);
    } catch (error) {
      this.toastsService.error({
        message: this.translate.instant(
          'INSTRUCTOR.SETTINGS_CONTENT_MODAL.UNPAIR_PRODUCT_FAILED',
        ),
      });
    }
  }

  openUnpairModal() {
    this.modalService.open(this.unpairModal, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
  }

  handleModalEvent(event: UPBsModalEvent) {
    if (event.type === ModalSubmissionType.COMPLETE_SUBMIT_BUTTON) {
      this.unpairProduct();
    }
    this.modalService.dismissAll();
  }
}
