<header>
  <div class="d-flex justify-content-between mt-2 mx-2">
    <div class="d-flex mx-4">
      <img
        [src]="logo"
        [alt]="'GENERIC.LOGO' | translate"
        data-automation-id="ebook-instructor-logo" />
    </div>
    <div class="d-flex">
      @if (isShowAssignButton()) {
        <div class="col d-flex px-2">
          <button
            type="button"
            data-automation-id="assign-btn"
            class="btn btn-primary btn-sm"
            (click)="onClickAssignButton()">
            {{ "GENERIC.BUTTONS.ASSIGN" | translate }}
          </button>
        </div>
      }
      @if (isShowUnpairButton()) {
        <div class="col px-2">
          <button
            class="btn btn-outline-primary btn-sm"
            type="button"
            data-automation-id="unpair-btn"
            (click)="openUnpairModal()">
            {{ "GENERIC.BUTTONS.UNPAIR" | translate }}
          </button>
        </div>
      }
      @if (isShowExitButton()) {
        <div class="col px-2">
          <button
            class="btn btn-outline-primary btn-sm"
            type="button"
            data-automation-id="exit-btn"
            (click)="onExit()">
            {{ "GENERIC.BUTTONS.EXIT" | translate }}
          </button>
        </div>
      }
    </div>
  </div>
</header>

<!-- Unpair Modal -->
<ng-template #unpairModal>
  <up-bs-modal
    [config]="unpairModalConfig"
    (modalEvent)="handleModalEvent.bind(this)($event)">
    <div>
      @if (isAssigned()) {
        <p
          data-automation-id="ins-unpair-text"
          [innerHTML]="
            'INSTRUCTOR.UNPAIR_MODAL.TEXT_ASSIGNED'
              | translate: { ebookName: ebookName() }
          "></p>
      } @else {
        <p
          data-automation-id="ins-unpair-text"
          [innerHTML]="
            'INSTRUCTOR.UNPAIR_MODAL.TEXT'
              | translate: { ebookName: ebookName() }
          "></p>
      }
    </div>
  </up-bs-modal>
</ng-template>
